@import '../../styles/vars.scss';

.gift-certificates-module {
    background-color: $black1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow: hidden;

    @include respond-to('min', $large-width) {
      min-height: 450px;
    }

    &-background {
      position: absolute;
      width: 100%;
      left: 0px; right: 0px;
      top: 0px; bottom: 0px;
      margin: auto;
      z-index: 0;

      /* Maximum aspect ratio */
      @media (max-aspect-ratio: 12/10) {
        height: 100%;
        width: auto;
      }
    }

    &-heading {
      margin-bottom: 1rem;
      color: $white1;
      z-index: 1;
      font-family: 'roboto';
      text-align: center;
    }

    &-copy {
      text-align: center;
      z-index: 1;
      max-width: 605px;

      @include respond-to('max', $small-width) {
        max-width: 400px;
      }

      p {
        z-index: 1;
        margin-top: 0px;
        color: $white2;
        font-family: 'roboto';
      }
    }
  }