@import '../../styles/vars.scss';

.video-player-module {

    .preview-image {
        max-width: 100%;
    }

    .play-button {
        display: block;
        position: absolute;
        background-color: rgba(#000, 0.5);
        height: 100%;
        width: 100%;
        top: 0px; bottom: 0px;
        left: 0px; right: 0px;
        margin: auto;
        border: none;
        cursor: pointer;
        transition: all .3s ease-out;

        &:hover {
            background-color: rgba(#000, 0.7);
        }

        >span {
            display: inline-block;
            background-color: #DEDFE3;
            font-family: 'roboto-light';
            border-radius: 50px;
            height: 70px;
            width: 70px;
            line-height: 70px;
            font-size: 16px;
        }
    }
}