@import '../../styles/vars.scss';

.pricing-module {
    @include respond-to('max', $small-width) {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pricing-background {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: flex-start;
        align-content: center;
        align-items: center;
        text-align: center;

        padding-top: 4rem;
        padding-bottom: 4rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: $green1;
        background-image: url(#{$ASSET_PATH}images/components/contour-lines.jpg);
        background-size: cover;
        background-repeat: no-repeat;

        &.mini {
            padding-top: 3rem;
            padding-bottom: 5rem;
            padding-left: 5rem;
            padding-right: 5rem;
            max-width: 600px;
            float: right;

            .pricing-title {
                margin-bottom: 0px;
            }

            @include respond-to('max', $large-width) {
                float: left;
                width: 100%;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }

        &.dark {
            background-image: none;
            background-color: $black2;
        }

        // .pricing-title {
        //     margin-bottom: 2rem;
        //     color: $white1;

        //     @include respond-to('max', $small-width) {
        //         font-size: 1.375rem;
        //         margin-top: 0px;
        //         margin-bottom: 0px;
        //     }
        // }

        .pricing-rate {
            color: $white1;
            margin-bottom: 2.5rem;

            @include respond-to('max', $medium-width) {
                margin-bottom: 1.5rem;
            }
            
            sup {
                top: -2.1rem;
                right: -10px;
                font-size: 14px;
                @include respond-to('max', $small-width) {
                    top: -1.2rem;
                }
            }
        }

        .original-rate {
            color: $white1;
            margin-top: 0rem;
            margin-bottom: 2.5rem;
            font-family: 'roboto-medium' !important;

            @include respond-to('max', $medium-width) {
                margin-bottom: 1.5rem;
            }

            .line {
                @include respond-to('max', $medium-width) {
                    display: block;
                    line-height: 1rem;
                }
            }
        }

        .pricing-conditions {
            color: #FFFFFF;
            margin-top: 2.5rem;
            margin-bottom: 0px;

            @include respond-to('max', $medium-width) {
                margin-top: 1.5rem;
                max-width: 300px;
            }

            @include respond-to('max', $small-width) {
                font-family: 'roboto';
            }
        }

        .pricing-booking-cta {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }
    }
}