@import '../vars.scss';

#page-landing {
    background-color: $white1;
    min-height: 100vh;

    .section-brand-introduction {
        display: block;
        position: relative;
        // height: calc(100vh - 70px);
        background-color: $black2;
        background-image: url(#{$ASSET_PATH}images/home/golf-ball.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        
        .section-brand-introduction-content {
            position: absolute;
            background-color: rgba(#000000, 0.7);
            width: 100%;
            height: 100%;
            top: 0px;
            bottom: 0px;
            padding-bottom: 40px;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            .brand-title {
                max-width: 700px;
                margin-bottom: 0px;
                font-family: 'roboto';
                @include respond-to('max', $small-width) {
                    margin-top: 0px;
                    max-width: 240px;
                }
            }
    
            .brand-description {
                max-width: 700px;
                margin-top: 1.5rem;
                margin-bottom: 0px;
                color: #FFFFFF;
    
                @include respond-to('max', $small-width) {
                    max-width: 268px;
                }
    
                @include respond-to('max', '350px') {
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
    
            .booking-cta {
                margin-top: 3.5rem;
            }
        }


    }

    .section-features {
        margin-top: 70px;
        margin-bottom: 70px;
        

        .feature-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: flex-start;
            align-content: center;
            align-items: center;
            text-align: center;
            margin-top: 5rem;
            margin-bottom: 5rem;

            @include respond-to('max', $small-width) {
                margin-top: 0px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }

            img {
                margin-bottom: 0px;
            }

            .feature-title {
                margin-top: 40px;
                margin-bottom: 16px;
                color: $black2;
            }

            .feature-description {
                color: $black3;
                width: 90%;
                max-width: 350px;
                margin-top: 0px;
            }
        }
    }

    .section-memberships {
        margin-top: 7rem;

        &-copy {
            margin: auto;
            max-width: 801px;
            text-align: center;
            margin-bottom: 1rem;
        }
    }

    .section-service {
        margin-top: 8rem;
        margin-bottom: 8rem;

        .section-service-content {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include respond-to('max', $small-width) {
                margin-top: 5rem;
            }

            .service-section-title {
                color: $black2;
                margin-bottom: 0px;
            }
    
            .service-section-description {
                margin-top: 2.5rem;
                margin-bottom: 0;
            }
            
            .how-it-works-cta {
                margin-top: 2.5rem;
            }
        }
    }

}
