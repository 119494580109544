/*
 * ====================================================================
 * vars.scss
 * 	Used to set variables used throughout scss files
 * ====================================================================
 */
$ASSET_PATH: '/assets/' !default;
$images: '#{$ASSET_PATH}images/';
$svgs: '#{$ASSET_PATH}svg/';

@mixin respond-to($condition, $media) {
  @media only screen and (#{$condition}-width: #{$media}) {
      @content;
  }
}

@mixin linear-gradient($from, $to, $stop, $angle:0) {
  background: $to;
	background: -moz-linear-gradient(#{$angle}deg, $from 0%, $from $stop, $to 100%);
	background: -webkit-gradient(linear, left #{$angle}deg, left bottom, color-stop(0%,$from), color-stop($stop,$from), color-stop(100%,$to));
	background: -webkit-linear-gradient(#{$angle}deg, $from 0%, $from $stop,$to 100%);
	background: -o-linear-gradient(#{$angle}deg, $from 0%, $from $stop,$to 100%);
	background: linear-gradient(to #{$angle}deg, $from 0%, $from $stop,$to 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}

@mixin orientation($orientation) {
	@media (orientation: $orientation) {
		@content;
	}
}

$scrollbar-size: 4px;

$white1: #FAFAFA;
$white2: #D8D8D8;
$white3: #EDEDED;
$black1: #343434;
$black2: #282828;
$black3: #4F4F4F;
$orange1: #CA701D;
$green1: #318A4F;
$gray1: #A8A8A8;

$xlarge-width: '1199px';
$large-width: '991px';
$medium-width: '767px';
$small-width: '575px';

$grid-columns: 12;
$grid-gutter-width: 20px;
