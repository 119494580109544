@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';

.footer {
  background-color: $white1;
  width: 100%;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;

  @include respond-to('max', $large-width) {
    padding-top: 1.5rem;
    padding-bottom: 5.5rem;
  }

  @include respond-to('max', $small-width) {
    padding-top: 1.5rem;
    padding-bottom: 7.5rem;
  }

  .column-heading {
    margin-bottom: 1rem;
    color: $black1;
  }
  
  .column-link-list {
    list-style: none;
    padding-left: 0px;

    li {
      transition: all .2s ease-out;
      
      > a {
        display: block;
        color: #000000;
        font-size: 1rem;
        line-height: 1.9rem;

        &:hover {
          text-decoration: none;
          color: $green1;
        }
      }
    }
  }

  .footer-logo {
    width: 180px;
    margin-right: 50px;

    @include respond-to('max', $large-width) {
      max-width: 60%;
      margin-right: 0px;
    }
  }

  .copyright-notice {
    display: inline-block;
    font-family: 'roboto';
    font-size: 1.125rem;
    color: $gray1;
    margin-bottom: 0px;
    margin-top: 10px;
    vertical-align: top;
    
    @include respond-to('max', $large-width) {
      display: block;
      margin-top: 3rem;
    }
  }

  .bottom-links-container {
    float: right;
    margin-top: 13px;

    @include respond-to('max', $large-width) {
      display: block;
      float: left;
    }

    .bottom-link {
      display: inline-block;
      font-size: 1rem;
      color: #1D2029;
      margin-right: 15px;
      transition: all .2s ease-out;
      border-right: 1px solid $black3;
      padding-right: 15px;
      line-height: 1rem;

      &:last-child {
        border-right: none;
      }

      &:hover {
        text-decoration: none;
        color: $green1;
      }

      @include respond-to('max', $small-width) {
        display: block;
        border-right: none;
        margin-bottom: 10px;
      }
    }
  }
}