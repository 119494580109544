@import '../../styles/vars.scss';

.memberships-module {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    
    .memberships-list {
        list-style: none;
        padding: 0px;
        justify-content: space-around;

        &-item {
            padding: 2rem;
            text-align: center;
            max-width: 350px;

            img {
                width: 100%;
            }
    
            // &:first-child {
            //     padding-left: 0px;
            // }
    
            // &:last-child {
            //     padding-right: 0px;
            // }
    
            // @include respond-to('max', $large-width) {
            //     padding-left: 1.5rem !important;
            //     padding-right: 1.5rem !important;
            // }
    
            // @include respond-to('max', $medium-width) {
            //     padding-left: 0px !important;
            //     padding-right: 0px !important;
            // }
    
            &-content {
                background-color: $white3;
                padding: 2rem 0.25rem;
    
                h3 {
                    font-family: 'roboto-italic';
                    color: $green1;
                    margin: 0px;
                }
    
                .membership-bonus {
                    color: $white1;
                    background-color: $green1;
                    width: calc(100% + 40px);
                    padding: 1.2rem 0px;
                    transform: translateX(-20px);
                    font-family: 'roboto';
                    font-size: 3rem;
                    line-height: 3rem;
                    margin-top: 1rem;
                    margin-bottom: 0px;
    
                    @include respond-to('max', $xlarge-width) {
                        font-size: 2.5rem;
                    }
                }
    
                .membership-subtitle {
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    font-size: 2rem;
    
                    @include respond-to('max', $xlarge-width) {
                        margin-bottom: 0rem;
                        font-size: 1.5rem;
                    }
                }
    
                .membership-description {
                    margin-top: 0.5rem;
                    font-size: 1.2rem;
    
                    @include respond-to('max', $xlarge-width) {
                        margin-top: 0rem;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    .cta-button {
        margin: auto;
        display: block;
    }

}