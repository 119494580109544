/*
 * ====================================================================
 * fonts.scss
 * Fonts go here, duh
 * ====================================================================
 */
$fonts: '#{$ASSET_PATH}fonts/';

// @font-face {
//     font-family: 'opensans';
//     src: url($fonts + 'opensans/opensans-regular.ttf');
//     font-weight: normal;
//     font-style: normal;
//     font-display: fallback;
// }

// @font-face {
//   font-family: 'opensans-light';
//   src: url($fonts + 'opensans/opensans-light.ttf');
//   font-weight: normal;
//   font-style: normal;
//   font-display: fallback;
// }

// @font-face {
//   font-family: 'opensans-semibold';
//   src: url($fonts + 'opensans/opensans-semibold.ttf');
//   font-weight: normal;
//   font-style: normal;
//   font-display: fallback;
// }

// @font-face {
//   font-family: 'opensans-bold';
//   src: url($fonts + 'opensans/opensans-bold.ttf');
//   font-style: normal;
//   font-display: fallback;
// }

@font-face {
  font-family: 'roboto';
  src: url($fonts + 'roboto/roboto-regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'roboto-italic';
  src: url($fonts + 'roboto/roboto-italic.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'roboto-medium';
  src: url($fonts + 'roboto/roboto-medium.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'roboto-light';
  src: url($fonts + 'roboto/roboto-light.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}